<template>
  <available-components-for-theme5 :specific-components="components" />
</template>
<script lang="ts">
import AvailableComponentsForTheme5 from '~/components/theme5/AvailableComponentsForTheme5.vue';
import { ViewsMixin } from '~/mixins/views.mixin';

export default defineNuxtComponent({
  name: 'Theme5View',

  mixins: [ViewsMixin],

  components: {
    AvailableComponentsForTheme5,
  },

  description:
    'Components that can be used through this theme should be imported here in order to use them dynamically',
});
</script>
